import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/home"
import FeaturedImage from "../components/FeaturedImage"
import { motion } from "framer-motion"
import parse from "html-react-parser"

const frame = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
}

const frameVariant = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "linear",
    },
  },
}

const leadFrame = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 2,
      when: "beforeChildren",
      staggerChildren: 0.07,
    },
  },
}

class Process extends React.Component {
  render() {
    let {
      location,
      data: {
        wpPage: {
          title,
          excerpt,
          content,
          featuredImage,
          acf_page_content: { lead },
          acf_cards: { cards },
          acf_postImage: {postImage},
          seo,
        },
      },
    } = this.props

    if (featuredImage && postImage) {
      featuredImage.node = postImage;
    }

    // console.log(featuredImage);

    const string = Array.from(lead)

    // Use default og:image
    // seo.opengraphImage = ""

    return (
      <Layout
        location={location}
        featuredImage={featuredImage?.node}
        cssClass="our-process"
        title={title}
        description={excerpt}
        seo={seo}
      >
        <section>
          <div className="row">
            <div className="col-sm-7 col-lg-11 col-xl-9">
              <motion.p
                className="lead"
                variants={leadFrame}
                initial="hidden"
                animate="visible"
              >
                {string.map((letter, index) => (
                  <motion.span key={index} variants={frameVariant}>
                    {letter}
                  </motion.span>
                ))}
              </motion.p>
              {parse(content)}
            </div>
            <FeaturedImage
              image={featuredImage?.node}
              page="our-process"
              classes="mobile"
            />
          </div>
          <motion.div
            className="row"
            variants={frame}
            initial="hidden"
            animate="visible"
          >
            {cards &&
              cards.map((item, index) => {
                // console.log(item.logo.imageFile.publicURL);
                return (
                  <motion.div
                    className="post col-sm-6 col-lg-6"
                    key={index}
                    variants={frameVariant}
                  >
                    <div className="cardwrapper">
                      <div className="card">
                        {item && item.icon && (
                          <div>
                            <img
                              src={item.icon.localFile.publicURL}
                              height="50"
                              alt={item.title}
                            ></img>
                          </div>
                        )}

                        <h2>{item.title}</h2>
                        {parse(item.copy)}
                      </div>
                    </div>
                  </motion.div>
                )
              })}
          </motion.div>
        </section>
      </Layout>
    )
  }
}

export default Process

export const pageQuery = graphql`
  {
    wpPage(databaseId: { eq: 1044 }) {
      id
      title
      excerpt
      content
      featuredImage {
        node {
          ...featuredImage
        }
      }
      acf_postImage {
        postImage {
          ...featuredImage
        }
      }
      acf_page_content {
        lead
      }
      acf_cards {
        cards {
          title
          copy
          icon {
            altText
            sourceUrl
            srcSet
            id
            modified
            localFile {
              extension
              publicURL
            }
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
